<template>
  <div id="tariffs" class="tariffs">
    <h2>Тарифы</h2>
    <div class="slider" v-if="filteredTariffs.length && status === 'success'">
      <div
        v-for="(item, index) in filteredTariffs"
        :key="index"
        class="tariff"
        :class="{ black: item.slug === 'black' || item.slug === 'premium' }"
      >
        <div class="tariff-item">
          <div class="tariff-title">{{ item.name }}</div>
          <!-- <div v-if="item.freezePrice" class="tariff-freeze">
            <img
              v-if="item.freezePrice.icon"
              :src="item.freezePrice.icon"
              alt=""
            />
            <span
              v-if="item.freezePrice.frontName"
              v-html="item.freezePrice.frontName"
            />
          </div>
          <div
            v-if="item.internetTele2_home && item.internetTele2_home.bonus"
            class="tariff-home"
          >
            <ToggleButton @on-click="item.showCost = !item.showCost" />
            <div class="tariff-home-info">
              <span
                v-if="item.internetTele2_home.frontName"
                v-html="item.internetTele2_home.frontName"
              />
              <span
                v-if="item.internetTele2_home.bonus"
                v-html="item.internetTele2_home.bonus"
              />
            </div>
          </div> -->
        </div>
        <div class="tariff-item">
          <div
            v-if="item.unlimitedTele2_calls"
            v-html="item.unlimitedTele2_calls"
            class="tariff-option string"
          />
          <div v-if="item.minutes" class="tariff-option">
            <b v-if="item.minutes?.value">
              {{ item.minutes.value }}
            </b>
            <span v-if="item.minutes?.frontName">
              минут {{ item.minutes.frontName }}
            </span>
          </div>
          <div v-if="item.internetPackage" class="tariff-option">
            <b v-if="item.internetPackage?.value">
              {{ item.internetPackage.value }}
              <sup v-if="item.internetPackage?.uom">
                {{ item.internetPackage.uom }}
              </sup>
            </b>
            <span
              v-if="
                !hideInternetPackage.includes(item.slug) &&
                item.internetPackage?.value &&
                item.internetPackage?.bonus
              "
              class="tariff-option tariff-option_span"
            >
              <b> +{{ item.internetPackage.value }}</b>
              <span
                >{{ item.internetPackage.uom }} каждый<br />
                3-й месяц</span
              >
            </span>
          </div>
          <div v-if="item.messagesPackage" class="tariff-option">
            <b v-if="item.messagesPackage?.value">
              {{ item.messagesPackage.value }}
            </b>
            <sup v-if="item.messagesPackage?.uom">
              {{ item.messagesPackage.uom }}
            </sup>
          </div>
        </div>
        <div class="tariff-item">
          <div class="tariff-list">
            <ul>
              <template
                v-for="(social, i) of getSocial(item.features)"
                :key="i"
              >
                <li v-if="social.icon && social.frontName">
                  <img :src="social.icon" alt="" />
                  <span v-html="remakeHREF(social.frontName)" />
                </li>
              </template>
              <li v-if="!item.price" class="tariff-custom-price">
                Единая стоимость звонков на все номера домашнего региона
              </li>
              <li v-if="!item.price" class="tariff-custom-price">
                <b>3,00 ₽</b> за минуту звонка на все номера домашнего региона и
                Tele2 России
              </li>
            </ul>
          </div>
        </div>
        <div v-if="item.hit" class="tariff-item tariff-flag">
          <div
            :style="{ backgroundColor: item.hit.color }"
            class="tariff-label"
          >
            {{ item.hit.text }}
          </div>
        </div>
        <div class="tariff-item">
          <div class="tariff-end">
            <div class="tariff-cart">
              <div v-if="item.price" class="tariff-price">
                <div class="tariff-price-cost">
                  <strong>{{ item.price }}<span>₽</span></strong>
                  <strong
                    v-if="item.internetTele2_home.bonus"
                    :class="{ hidden: !item.showCost }"
                  >
                    <span>
                      {{ +item.internetTele2_home.cost + +item.price }}
                    </span>
                  </strong>
                </div>
                <div>в месяц</div>
              </div>
              <RouterLink
                v-if="showMoreBtn(item.slug)"
                :to="{
                  path: `/detail/${item.slug}`,
                  query: $route.query,
                }"
                class="btn btn-default tariff-button"
              >
                Подробнее
              </RouterLink>
            </div>
            <div v-if="existModalsSuit.includes(item.slug)" class="tariff-more">
              <!-- <button
                    @click="openModalSuit(item.slug)"
                    class="btn-link btn-block tariff-button tariff-button-suit"
                  >
                    <span>Узнать кому подойдет</span>
                  </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalSuit
    v-if="!!modalShowSuit"
    :slug="modalShowSuit"
    @close="modalShowSuit = false"
  />
</template>

<script>
import { mapState } from "vuex";

import ModalSuit from "./ModalSuit.vue";
// import ToggleButton from "@/components/ToggleButton.vue";

export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [
          { slug: "my-conversation" },
          { slug: "my-online" },
          { slug: "my-online-plus" },
          { slug: "gaming" },
          { slug: "black" },
          { slug: "premium" },
          { slug: "classic" },
        ];
      },
    },
  },
  components: {
    ModalSuit,
    // ToggleButton,
  },
  data() {
    return {
      modalShowSuit: false,
      existModalsSuit: [
        "my-conversation",
        "my-online",
        "my-online-plus",
        "gaming",
        "black",
        "classic",
      ],
      hideInternetPackage: ["prozrachnie-granicy-m", "prozrachnie-granicy-l"],
    };
  },
  computed: {
    ...mapState({
      tariffs: (state) => state.api.tariffs,
      status: (state) => state.api.tariffsStatus,
    }),
    filteredTariffs() {
      const tarifsToShow = [
        "black",
        "super-online-plus",
        "my-online-plus",
        "my-online",
        "my-conversation",
        "gaming",
        "premium",
        "prozrachnie-granicy-m",
        "prozrachnie-granicy-l",
      ];
      return this.tariffs.filter((t) => tarifsToShow.includes(t.slug));
    },
  },
  methods: {
    openModalSuit(slug) {
      this.modalShowSuit = slug;
    },
    fullPath(url) {
      return `https://t2.ru${url}`;
    },
    showMoreBtn(slug) {
      return [
        "black",
        "my-online",
        "my-online-plus",
        "my-conversation",
        "prozrachnie-granicy-m",
        "prozrachnie-granicy-l",
      ].includes(slug);
    },
    remakeHREF(str) {
      if (str.includes("Selection")) {
        return str.replace(
          "/bolshe/selection?pageParams=view%3Dlanding",
          "https://msk.t2.ru/bolshe/selection?pageParams=view%3Dlanding"
        );
      }
      return str;
    },
    getSocial(features) {
      // remove if link fix
      const brokenString =
        '<a href="/option/4g-turbo">4G Turbo</a> и <a href="https://selection.t2.ru/">привилегии программы Tele2 Selection</a>';
      const broken = features.advantages.find(
        (a) => a.frontName === brokenString
      );
      if (broken) {
        broken.frontName =
          '<a href="https://t2.ru/option/4g-turbo" target="_blank">4G Turbo</a> и <a href="https://selection.t2.ru/" target="_blank">привилегии программы Tele2 Selection</a>';
      }
      // end remove if link fix
      return [
        ...features.advantages,
        ...features.gamingAdvantages,
        features.messengers,
        features.socialNetworks,
      ];
    },
  },
};
</script>

<style lang="scss">
.tariffs {
  background-color: #fff;
  padding-top: var(--sectionOffsetTop);
  padding-bottom: var(--sectionOffsetBottom);
  padding-left: var(--widthGutter);
  padding-right: var(--widthGutter);
  max-width: var(--widthContainer);
  margin: 0 auto;
  @include lg {
    padding: 50px var(--widthGutter);
    background-color: #f5f5f5;
    max-width: calc(var(--widthContainer) + var(--widthGutter) * 2);
    margin: 0 auto;
  }
  > h2 {
    @include lg {
      padding: 0;
    }
  }
  &__inner {
    max-width: var(--widthContainer);
    margin: 0 auto;
  }
  .slider {
    display: grid;
    row-gap: 16px;
    width: 100%;
    // margin-top: -16px;
    @include lg {
      max-width: calc(var(--widthContainer) + 48px);
      margin: 0 auto;
      // padding: 0px 24px;
    }
    .swiper {
      @include lg {
        padding: 24px;
        margin: 0 -24px;
      }
    }
    .swiper-wrapper {
      align-items: stretch;
    }
    .swiper-slide {
      width: auto;
    }
    &-item {
      // padding: var(--widthGutter);
      height: auto;
    }
    &-content {
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 16px rgba(31, 34, 41, 0.12);
    }
    &-title {
      font-size: 40px;
    }
  }

  .tariff {
    height: calc(100% - 32px);
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(31, 34, 41, 0.12);
    margin: 16px 0;
    padding: 16px 18px;
    position: relative;
    display: grid;
    // grid-template-rows: 144px;
    @include lg {
      width: 100%;
      height: 256px;
      display: grid;
      padding: 27px 54px 27px 27px;
      grid-template-columns:
        minmax(180px, 220px) minmax(200px, 220px) minmax(350px, 370px)
        160px;
      margin: 0;
      grid-gap: 5px;
    }
    &-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      &:last-of-type {
        margin-top: 20px;
        justify-content: flex-end;
        @include lg {
          margin-left: 20px;
          justify-content: flex-start;
          margin-top: 0;
        }
      }
      &:nth-of-type(2) {
        min-height: 150px;
      }
      @include lg {
        &:last-of-type {
          justify-content: flex-start;
        }
      }
    }
    &.black {
      background: linear-gradient(
        164.79deg,
        #282c34 0%,
        #373c48 83%,
        #494e59 87.51%,
        #373c48 91.88%,
        #282c34 100%
      );
      color: #fff;
      .tariff-button {
        border-color: #fff;
      }
      .tariff-list img {
        filter: brightness(0) invert(1);
      }
    }
    &-flag {
      margin-top: 20px;
      @include lg {
        position: absolute;
        left: 0px;
        top: 210px;
        margin-top: 0;
        .tariff-label {
          margin-top: 0;
        }
      }
      @include xl {
        transform: none;
        top: 187px;
      }
    }
    &-title {
      font-family: var(--fontSerif);
      font-size: 24px;
      line-height: 36px;
      @include lg {
        font-weight: 700;
        font-size: 28px;
        line-height: 34px;
        margin-bottom: 0;
      }
    }
    &-option {
      font-size: 16px;
      line-height: 22px;
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 6px;
      margin-bottom: 3px;
      &.string {
        display: inline-block;
      }
      &.tariff-option_span {
        align-items: center;
        font-size: 14px;
        margin-bottom: 0;
        color: #ff59a3;
        @include lg {
          align-items: flex-end;
        }
      }
      @include lg {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 7px;
      }
      span {
        @include lg {
          font-size: 14px;
          line-height: 18px;
          max-width: 170px;
        }
      }
      b {
        font-family: var(--fontSerif);
        font-size: 25px;
        line-height: 36px;
        font-weight: 700;
        @include lg {
          font-size: 28px;
        }
      }
      sup {
        font-size: 14px;
        font-weight: 700;
      }
    }
    &-list {
      @include lg {
        margin-top: 0;
      }
      ul {
        @include reset-list;
        display: grid;
        gap: 10px;
        @include lg {
          gap: 16px;
        }
        li {
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 6px;
          @include lg {
            gap: 9px;
          }
          img {
            width: 18px;
            height: 18px;
            object-fit: contain;
            object-position: 50%;
          }
          span {
            font-size: 16px;
            line-height: 20px;
            @include lg {
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
    }
    &-label {
      width: 123px;
      position: relative;
      font-family: var(--fontSerif);
      display: inline-block;
      background: #ff59a3;
      font-size: 13px;
      line-height: 18px;
      padding-left: 25px;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-right: 20px;
      margin-left: -18px;
      color: #fff;
      @include lg {
        margin-left: 0;
      }
      &::before {
        position: absolute;
        top: 0;
        right: -1px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 16px 8px 16px 0;
        border-color: transparent #ffffff transparent transparent;
        content: "";
      }
    }
    &-end {
      height: auto;
      min-height: 80px;
    }
    &-cart {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: flex-end;
      justify-content: space-between;
      @include lg {
        grid-template-columns: 1fr;
        justify-content: flex-start;
        margin-top: 0;
      }
    }
    &-price {
      font-size: 14px;
      line-height: 12px;

      &-cost {
        display: flex;
        // align-items: center;
        flex-direction: column-reverse;

        strong:nth-child(2) {
          span {
            position: relative;
            &::before {
              content: "";
              position: absolute;
              top: 8px;
              bottom: 0;
              left: 0;
              right: -3px;
              display: block;
              border-top: 3px solid #ff59a3;
              transform: rotate(-20deg);
            }
          }
          &.hidden {
            visibility: hidden;
          }
        }

        @include lg {
          align-items: center;
          flex-direction: row;
        }
      }
      @include lg {
        margin-left: auto;
        margin-bottom: 17px;
        font-size: 16px;
        line-height: 22px;
        width: 100%;
      }
      strong {
        font-family: var(--fontSerif);
        font-weight: 700;
        font-size: 32px;
        line-height: 28px;
        @include lg {
          font-size: 38px;
          line-height: 44px;
          display: flex;
          align-items: flex-start;
        }
      }
      span {
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        padding-left: 6px;
        @include lg {
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
          padding-left: 2px;
        }
      }
    }
    &-home {
      display: grid;
      grid-template-columns: 50px auto;
      column-gap: 10px;
      align-items: center;
      margin: 10px 0;
      &-info {
        display: flex;
        flex-direction: column;
      }
    }
    &-custom-price {
      display: block !important;
      font-size: 16px;
      b {
        font-size: 18px;
      }
      @include lg {
        font-size: 14px;
        b {
          font-size: 16px;
        }
      }
    }
    &-more {
      margin-top: 18px;
      text-align: center;
    }
    &-button {
      &-suit {
        @include reset-button;
      }
      @include lg {
        font-size: 14px;
        line-height: 17px;
        padding: 0;
      }
    }
    &-freeze {
      display: flex;
      align-items: flex-end;
      column-gap: 5px;
      margin-top: 5px;
      color: #3fcbff;

      img {
        height: 22px;
        width: 22px;
      }
    }
  }
  .swiper-pagination {
    margin-top: 8px;
  }
}
</style>

<style lang="scss">
.tariff-list span a {
  text-decoration: underline;
}
</style>
