<template>
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.292893 0.292903C0.683418 -0.0976215 1.31658 -0.0976215 1.70711 0.292903L6 4.5858L10.2929 0.292904C10.6834 -0.0976207 11.3166 -0.0976206 11.7071 0.292904C12.0976 0.683428 12.0976 1.31659 11.7071 1.70712L6.70711 6.70712C6.51957 6.89465 6.26522 7.00001 6 7.00001C5.73478 7.00001 5.48043 6.89465 5.29289 6.70712L0.292893 1.70712C-0.0976311 1.31659 -0.0976311 0.683427 0.292893 0.292903Z"
      fill="currentColor"
    />
  </svg>
</template>
