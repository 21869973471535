<template>
  <section class="tarifs">
    <h2 class="tarifs__title">Выгода тарифов</h2>
    <div class="tarifs__items">
      <div
        v-for="(item, i) of items"
        :key="i"
        class="tarifs__item"
        :class="`tarifs__item_${i}`"
      >
        <h3 class="tarifs__item-title">{{ item.name }}</h3>
        <p class="tarifs__item-text">{{ item.text }}</p>
        <a
          v-if="item.url"
          :href="item.url"
          class="tarifs__item-btn"
          target="_blank"
        >
          Попробовать
        </a>
        <picture v-if="item.image" class="tarifs__item-image">
          <source
            v-if="item.image?.desktop"
            media="(min-width:1024px)"
            :srcset="require(`@/assets/detail/${item.image.desktop}.svg`)"
          />
          <img
            :src="require(`@/assets/detail/${item.image.mobile}.svg`)"
            alt="item"
          />
        </picture>
      </div>
    </div>
  </section>
</template>

<script setup>
import { regions } from "@/constants";
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const regionConfirm = computed(() => store.state.api.regionConfirm);
const subDomain = computed(() => regions[regionConfirm.value.siteId].slug);

/**
 * @typedef TarifsItem
 * @prop {string} name
 * @prop {string} text
 * @prop {string} [url]
 * @prop {{mobile?: string, desktop?: string}} [image]
 */

/**
 * @type {TarifsItem[]}
 */
const items = [
  {
    name: "MiXX",
    text: "Слушайте музыку, смотрите кино, пользуйтесь интернетом\xA0и другими сервисами в\xA0одной подписке",
    url: `https://${subDomain.value}.t2.ru/mixx-max`,
    image: {
      mobile: "item-1",
      desktop: "item-1-desktop",
    },
  },
  {
    name: "Безлимит на мессенджеры и\xA0соцсети",
    text: "Общайтесь, не думая о том, что интернет-трафик скоро закончится",
  },
  {
    name: "Вечные минуты и\xA0ГБ",
    text: "Остатки минут и\xA0ГБ не\xA0сгорают, используйте\xA0их, когда\xA0захотите",
    url: "https://more.t2.ru/#allLeft",
    image: {
      mobile: "item-3",
      desktop: "item-3-desktop",
    },
  },
  {
    name: "Делитесь гигабайтами",
    text: "Делитесь интернет-трафиком с другими абонентами Tele2 по всей стране",
    url: "https://more.t2.ru/#shareGb",
  },
  {
    name: "Заморозка цены",
    text: "Абонентская плата не изменится до конца 2025 г. при подключении или переходе на тарифы линейки «Мой Tele2», а также Black и Premium",
  },
  {
    name: "Не\xA0блокируем при\xA0минусе",
    text: "Пользуйтесь оплаченными пакетами минут и\xA0ГБ даже при\xA0отрицательном балансе",
  },
];
</script>

<style lang="scss">
.tarifs {
  // .tarifs__title
  &__title {
    margin: 0px 0px 32px 0px;
    font-weight: 700;
    font-size: 25px;
    line-height: calc(32 / 25 * 100%);
    color: #1f2229;
  }
  // .tarifs__items
  &__items {
    display: grid;
    gap: 11px 10px;
  }
  // .tarifs__item
  &__item {
    position: relative;
    padding: 10px 16px 16px;
    background-color: #ffffff;
    border-radius: 6px;
    min-height: 154px;
    overflow: hidden;

    .tarifs__item-image {
      position: absolute;
      bottom: 0px;
      right: 4px;
    }
  }
  // .tarifs__item_0
  &__item_0 {
    .tarifs__item-text {
      max-width: 80%;
    }
  }
  // .tarifs__item_2
  &__item_2 {
    .tarifs__item-text {
      max-width: 80%;
    }
  }
  // .tarifs__item-title
  &__item-title {
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 18px;
    line-height: calc(22 / 18 * 100%);
    font-family: var(--fontSerif);
    text-transform: none;
    color: #1f2229;
  }
  // .tarifs__item-text
  &__item-text {
    position: relative;
    z-index: 2;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: calc(20 / 14 * 100%);
    color: #1f2229;
  }
  // .tarifs__item-btn
  &__item-btn {
    visibility: hidden;
    position: relative;
    z-index: 2;
    margin-top: 16px;
    @include btn-stroke;
  }
  // .tarifs__item-image
  &__item-image {
    display: inline-block;
    line-height: normal;
    font-size: 0;
  }
}

.tarifs.purple {
  .tarifs__item-image {
    background-color: #ff59a3;
  }
}
.tarifs.blue {
  .tarifs__item-image {
    background-color: #3fcbff;
  }
}
.tarifs.shadow {
  .tarifs__item {
    box-shadow: 0px 4px 16px rgba(31, 34, 41, 0.12);
  }
}

@media (min-width: 768px) {
  .tarifs {
    // .tarifs__title
    &__title {
      font-size: 38px;
      line-height: calc(42 / 38 * 100%);
    }
    // .tarifs__items
    &__items {
      grid-template-columns: repeat(2, 1fr);
    }
    // .tarifs__item
    &__item {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      padding: 16px 24px 24px;

      .tarifs__item-text {
        flex: 1 1 auto;
      }
    }
    // .tarifs__item_0
    &__item_0 {
      min-height: 198px;
      .tarifs__item-text {
        max-width: 80%;
      }
    }
    // .tarifs__item_2
    &__item_2 {
      min-height: 198px;
      .tarifs__item-text {
        max-width: 80%;
      }
      .tarifs__item-image {
        img {
          height: 138px;
        }
      }
    }
    // .tarifs__item_3
    &__item_3 {
      min-height: 198px;
    }
    // .tarifs__item-title
    &__item-title {
      font-size: 22px;
      line-height: calc(28 / 22 * 100%);
    }
    // .tarifs__item-text
    &__item-text {
      font-size: 16px;
      line-height: calc(22 / 16 * 100%);
    }
    // .tarifs__item-btn
    &__item-btn {
    }
    // .tarifs__item-image
    &__item-image {
      display: inline-block;
    }
  }
}
@media (min-width: 1024px) {
  .tarifs {
    // .tarifs__title
    &__title {
    }
    // .tarifs__items
    &__items {
      grid-template-areas: "a a a a a b b b b" "c c c c c c c c c" "d d d e e e f f f";
      grid-template-columns: none;

      .tarifs__item_0 {
        grid-area: a;
      }
      .tarifs__item_1 {
        grid-area: b;
      }
      .tarifs__item_2 {
        grid-area: c;
      }
      .tarifs__item_3 {
        grid-area: d;
      }
      .tarifs__item_4 {
        grid-area: e;
      }
      .tarifs__item_5 {
        grid-area: f;
      }
    }
    // .tarifs__item
    &__item {
    }
    // .tarifs__item_0
    &__item_0 {
      .tarifs__item-text {
        max-width: 60%;
      }
      .tarifs__item-image {
        img {
          height: 178px;
        }
      }
    }
    // .tarifs__item_2
    &__item_2 {
      .tarifs__item-text {
        max-width: 35%;
      }
      .tarifs__item-image {
        img {
          height: 198px;
        }
      }
    }
    // .tarifs__item_3
    &__item_3 {
    }
    // .tarifs__item-title
    &__item-title {
    }
    // .tarifs__item-text
    &__item-text {
    }
    // .tarifs__item-btn
    &__item-btn {
    }
    // .tarifs__item-image
    &__item-image {
    }
  }
}
</style>
