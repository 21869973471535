import script from "./App.vue?vue&type=script&setup=true&lang=js"
export * from "./App.vue?vue&type=script&setup=true&lang=js"

import "./App.vue?vue&type=style&index=0&id=76bedb8e&lang=scss"
import "@/styles/button.scss?vue&type=style&index=1&lang=scss"
import "@/styles/swiper.scss?vue&type=style&index=2&lang=scss"
import "@/styles/collapse.scss?vue&type=style&index=3&lang=scss"

const __exports__ = script;

export default __exports__