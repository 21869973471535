<template>
  <footer class="footer">
    <div class="footer__inner">
      <p>{{ getText + " " + new Date().getFullYear() }}</p>
      <p>{{ getText2 }}</p>
      <button class="footer__button" @click="scroll('#app')"></button>
    </div>
  </footer>
</template>

<script setup>
import useLanguage from "@/hooks/useLanguage";
import { computed } from "vue";

const { getLanguage } = useLanguage();

const getText = computed(() => {
  const lang = getLanguage();
  if (lang === "ky") return "© t2 Орусия,";
  if (lang === "tg") return "© t2 Русия,";
  if (lang === "uz") return "© t2 Rossiya,";
  return "© t2 Россия,";
});
const getText2 = computed(() => {
  const lang = getLanguage();
  if (lang === "ky") return "Толук маалымат сайтта t2.ru";
  if (lang === "tg") return "Маълумоти муфассал дар сайт t2.ru";
  if (lang === "uz") return "Saytdagi tafsilotlar t2.ru";
  return "Подробная информация на сайте t2.ru";
});
</script>

<style scoped lang="scss">
.footer {
  background: #34383f;
  display: grid;
  place-items: center;
  color: var(--colorLightSecond);
  padding: 8px var(--widthGutter);
  position: relative;
  &__inner {
    width: 100%;
    max-width: var(--widthContainer);
    p {
      margin: 16px 0;
      font-size: 12px;
    }
  }
  &__button {
    @include reset-button;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='23' viewBox='0 0 20 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 21.1538c0 .5523.4477 1 1 1s1-.4477 1-1H9Zm1.7071-14.5532c-.3905-.3905-1.0237-.3905-1.4142 0l-6.364 6.3639c-.3905.3906-.3905 1.0237 0 1.4143.3906.3905 1.0237.3905 1.4142 0L10 8.7219l5.6569 5.6569c.3905.3905 1.0236.3905 1.4142 0 .3905-.3906.3905-1.0237 0-1.4143l-6.364-6.364ZM11 21.1538V7.3077H9v13.8461h2Z' fill='%23fff'/%3E%3Cpath stroke='%23fff' stroke-width='2' stroke-linecap='round' d='M1.7692 1.6923h16.4616'/%3E%3C/svg%3E");
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    right: var(--widthGutter);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: rgba(52, 56, 63, 0.8);
    border: 1px solid #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
  }
}
</style>
