<template>
  <div class="header-fixed">
    <header class="header">
      <div class="header__inner">
        <router-link
          class="header__logo"
          :to="{ name: 'Home', query: $route.query }"
        >
          <IconLogo />
        </router-link>
        <div class="header__right">
          <button @click="openModal" class="header__location">
            <img
              :src="staticUrl(`static/flags/${getLanguage()}.svg`)"
              width="24"
              height="24"
            />
            {{ getLang }}
            <span class="header__location-icon">
              <IconArrow />
            </span>
          </button>
        </div>
      </div>
    </header>
  </div>
</template>

<script setup>
import IconArrow from "@/components/icons/IconArrow.vue";
import IconLogo from "@/components/icons/IconLogo.vue";
import useLanguage from "@/hooks/useLanguage";
import useLanguageModal from "@/hooks/useLanguageModal";
import { staticUrl } from "@/utils";
import { computed } from "vue";

const { openModal } = useLanguageModal();
const { getLanguage } = useLanguage();

const getLang = computed(() => {
  const lang = getLanguage();
  if (lang === "ky") return "Тил";
  if (lang === "tg") return "Забон";
  if (lang === "uz") return "Til";
  return "Язык";
});
</script>

<style scoped lang="scss">
.header {
  --height: 48px;
  height: var(--height);
  background: var(--colorDarkSecond);
  display: grid;
  place-items: center;
  padding: 0 var(--widthGutter);
  &-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
  &__inner {
    width: 100%;
    max-width: calc(var(--widthContainer) + var(--widthGutter) * 2);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo {
    position: relative;
    top: 2px;
    z-index: 3010;
    color: #fff;
  }
  &__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    a {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      margin-right: 0;
      @include md {
        margin-right: 34px;
      }
    }
  }
  // .header__location
  &__location {
    display: flex;
    align-items: center;
    min-width: 97px;
    height: 28px;
    border-radius: 8px;
    border: 2px solid currentColor;
    color: #fff;
    padding: 2px 5px;
    background: transparent;
    transition: color 0.3s ease-in;

    font-size: 14px;
    font-weight: 400;

    img {
      margin-right: 5px;
    }

    &:hover {
      color: rgba(255, 255, 255, 0.7);
    }
  }
  // .header__location-icon
  &__location-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-left: 2px;
  }
}
</style>
