<template>
  <div class="modal">
    <div class="modal__wrap">
      <slot />
    </div>
  </div>
</template>

<script setup>
import useModal from "@/hooks/useModal";
import { onMounted, onUnmounted } from "vue";

const { closeModal } = useModal();

const keyPressEscape = (e) => {
  if (e.key == "Escape") {
    closeModal();
  }
};

onMounted(() => {
  window.addEventListener("keyup", keyPressEscape);
});

onUnmounted(() => {
  window.removeEventListener("keyup", keyPressEscape);
});
</script>

<style lang="scss">
.modal {
  --headerHeight: 0px;
  position: fixed;
  top: var(--headerHeight);
  left: 0;
  width: 100%;
  height: 100%;
  // padding: 16px 18px calc(50px + var(--headerHeight));
  background: #ffffff;
  overflow: auto;
  z-index: 1001;
  // .modal__wrap
  &__wrap {
  }
}

@media (min-width: 768px) {
  .modal {
    --headerHeight: 48px;
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 50px 0 calc(50px + var(--headerHeight));
    // .modal__wrap
    &__wrap {
    }
  }
}
</style>
