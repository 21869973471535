import useLanguage from "@/hooks/useLanguage";
import { unspanText } from "@/hooks/useTranslate";

const { getLanguage } = useLanguage();

/**
 * Возвращает текст из локального словаря для текущего языка если есть
 * иначе результат перевода с бека
 * @param {string} text
 * @param {import("@/hooks/useLanguage").LanguageCode} lang
 * @returns {string}
 */
const t = (text, lang) => {
  if (lang === "ru") return text;
  return dictionary[text][lang];
};
/**
 * Заменяет текст перевода на текст из локального словаря
 * для самых тяжелых случаев
 * @param {string[]} sourceTexts
 * @param {string[]} targetTexts
 * @returns {string[]}
 */
const fixT = (sourceTexts, targetTexts) => {
  const lang = getLanguage();

  sourceTexts.forEach((text, i) => {
    text = unspanText(text);
    if (text in dictionary && dictionary[text][lang]) {
      targetTexts[i] = t(text, lang);
    }
  });
  return targetTexts;
};

/**
 * Словарик может включать в себя перевод на 1 язык,
 * для других будет использоваться ответ с бека
 * спецсимволы HTML нужно заменить на аналогичные для js (&nbsp будет \xA0)
 */
const dictionary = {
  // " Бесплатный трафик Telegram, Viber, WhatsApp, TikTok, ВКонтакте, Одноклассники, YouTube":
  //   {
  //     ky: "Акысыз трафик Telegram, Viber, WhatsApp, Tik Tok, ВКонтакте, Одноклассники, YouTube",
  //     tg: "Трафики ройгон Telegram, Viber, WhatsApp, Tik Tok, ВКонтакте, Одноклассники, Youtube",
  //     uz: "Bepul trafik Telegram, Viber, WhatsApp, Tik Tok, ВКонтакте, Одноклассники, Youtube",
  //   },
  "Тысячи фильмов, сериалов и 100 ТВ-каналов от Wink": {
    // ky: 'Миңдеген тасмалар, сериалдар жана 100 телеканал Wink',
    tg: "Ҳазорҳо филмҳо, сериалҳо ва 100 каналҳои телевизионӣ аз Wink",
    // uz: 'Minglab filmlar, seriallar va 100 ta televizion kanallar Wink'
  },
  "More.tv, тысячи фильмов, сериалов и 100 ТВ-каналов от Wink": {
    tg: "More.tv, ҳазорҳо филмҳо, сериалҳо ва 100 каналҳои телевизионӣ аз Wink",
  },
  "Абонентская плата не изменится до конца 2025 г. при подключении или переходе на тарифы линейки «Мой Tele2», а также Black и Premium":
    {
      ky: "Абоненттик төлөм 2025-ж. аягына чейин «Меники Tele2» линейкасынын тарифтерине кошулганда же которулганда өзгөрбөйт, ошондой эле оот жана.",
      tg: "Пардохти абонентӣ то охири Соли 2025 ҳангоми пайвастшавӣ е гузаштан ба тарифҳои диапазон тағир намеебад «Ман Tele2», Инчунин Black Ва Premium",
      uz: "Abonent to'lovi 2025-yil oxirigacha «Mening Tele2», shuningdek, qora va Premium tariflariga ulanganda yoki tariflarga o'tishda o'zgarmaydi",
    },
  "Маркет Tele2: продавайте и\xA0покупайте минуты и\xA0ГБ": {
    uz: "Bozor Tele2: bir necha daqiqa va sotib oling GB",
  },
  "Заморозка цены до\xA0конца\xA02025\xA0г.": {
    uz: "2025 yil oxirigacha narxlarni muzlatish",
  },
};

export { dictionary, t, fixT };
