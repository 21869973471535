<template>
  <div class="home">
    <div class="home-inner">
      <div class="home-section">
        <div class="home-section__inner">
          <SectionTariffs />
          <DetailTarifs id="home-tariffs" class="home-tariffs purple" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import SectionTariffs from "@/components/SectionTariffs.vue";
import DetailTarifs from "@/components/detail/DetailTarifs.vue";
import useLanguage from "@/hooks/useLanguage";
import useTranslate from "@/hooks/useTranslate";
import { onMounted, watch } from "vue";
import { useStore } from "vuex";

const { getLanguage } = useLanguage();
const { translate } = useTranslate();
const store = useStore();

onMounted(async () => {
  store.commit("setProp", { isLoading: true });
  if (getLanguage() !== "ru") {
    await translate("#tariffs", document, "home");
    await translate("#home-tariffs", document, "frame-tariffs");
  }
  store.commit("setProp", { isLoading: false });
});

watch(getLanguage, async () => {
  store.commit("setProp", { isLoading: true });
  await translate("#tariffs", document, "home");
  await translate("#home-tariffs", document, "frame-tariffs");
  store.commit("setProp", { isLoading: false });
});
</script>

<style lang="scss">
.home {
  padding-top: 48px;
}

.home-tariffs {
  max-width: 1152px;
  padding: 48px 18px;
  margin: 0 auto;
}
</style>
