import { ref } from "vue";

const componentName = ref(null);
const modalProps = ref(null);

export default function useModal() {
  /**
   * @param {string} name - название компонента
   * @param {Object} props - пропсы для компонента. Передаются в виде обьекта
   */
  const openModal = (name, props) => {
    componentName.value = name;
    modalProps.value = props;
  };

  const closeModal = () => {
    componentName.value = null;
    modalProps.value = null;
  };

  return {
    openModal,
    closeModal,
    componentName,
    modalProps,
  };
}
