<template>
  <div class="modal" tabindex="-1" aria-modal="true" role="dialog">
    <div class="modal-dialog">
      <transition name="fade-out">
        <div v-if="show" class="modal-content" :class="{ black: tariff.black }">
          <div class="modal__head">
            <button class="modal__close" @click="close()"></button>
          </div>
          <div class="modal-grid">
            <div class="modal-column">
              <img
                class="modal-preview"
                :src="require(`@/assets/${tariff.image}`)"
              />
            </div>
            <div class="modal-column">
              <h1 class="modal-title">{{ tariff.title }}</h1>
              <div class="modal-cost">
                <div
                  v-for="(item, i) of new Array(tariff.rubs)"
                  :key="i"
                  class="icon"
                >
                  <img src="@/assets/icons/rub.svg" />
                </div>
                <span>₽/месяц</span>
              </div>
              <div class="modal-fullness">
                <div class="modal-fullness__item">
                  <div
                    v-for="(item, i) of new Array(4)"
                    :key="i"
                    class="modal-fullness__line"
                    :class="{ active: i < tariff.gb }"
                  ></div>
                  <div class="modal-fullness__title">ГБ</div>
                </div>
                <div class="modal-fullness__item">
                  <div
                    v-for="(item, i) of new Array(4)"
                    :key="i"
                    class="modal-fullness__line"
                    :class="{ active: i < tariff.min }"
                  ></div>
                  <div class="modal-fullness__title">МИН</div>
                </div>
                <div class="modal-fullness__item">
                  <div
                    v-for="(item, i) of new Array(4)"
                    :key="i"
                    class="modal-fullness__line"
                    :class="{ active: i < tariff.sms }"
                  ></div>
                  <div class="modal-fullness__title">SMS</div>
                </div>
              </div>

              <div v-if="tariff.social_media" class="modal-social">
                <div class="modal-social__title">
                  <strong>Безлимит</strong> на соцсети и мессенджеры:
                </div>
                <div class="socials">
                  <img
                    v-for="item of tariff.social_media"
                    :key="item"
                    :src="require(`@/assets/icons/social-media/${item}.svg`)"
                  />
                </div>
              </div>
              <div v-if="tariff.wink" class="wink">
                <img src="@/assets/icons/social-media/wink.svg" />
                <span v-html="tariff.wink" />
              </div>
              <div v-if="tariff.game_option" class="wink">
                <img src="@/assets/icons/social-media/game.svg" />
                <span v-html="tariff.game_option" />
              </div>
            </div>
            <div class="modal-column">
              <h2 class="modal-title__suit">Подойдет клиенту, который:</h2>
              <div class="modal-desc">
                <ul>
                  <li v-for="(item, i) of tariff.list" :key="i" v-html="item" />
                </ul>
              </div>
            </div>
            <button
              @click="close()"
              class="btn btn-default btn-close"
              type="button"
            >
              Закрыть
            </button>
          </div>
        </div>
      </transition>
    </div>
  </div>
  <transition name="fade">
    <div v-if="show" class="modal-backdrop"></div>
  </transition>
</template>

<script>
import { tariffs } from "@/constants";
export default {
  name: "ModalSuit",
  emits: ["close"],
  props: {
    slug: {
      type: [String, Boolean],
    },
  },
  data() {
    return {
      show: false,
      tariffs,
    };
  },
  created() {
    document.body.classList.add("modal-open");
    document.addEventListener("keyup", this.keyPressEscape);
  },
  mounted() {
    this.show = true;
  },
  unmounted() {
    document.body.classList.remove("modal-open");
    document.removeEventListener("keyup", this.keyPressEscape);
  },
  computed: {
    tariff() {
      return this.tariffs[this.slug];
    },
  },
  methods: {
    keyPressEscape(e) {
      if (e.key == "Escape") {
        this.close();
      }
    },
    close() {
      this.show = false;
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5055;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  @include lg {
    padding: 24px;
  }
  &-dialog {
    position: relative;
    width: auto;
    pointer-events: none;
    display: flex;
    min-height: 100%;
    margin: 0 auto;
    @include lg {
      max-width: var(--widthContainer);
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    padding-bottom: 24px;
    justify-content: space-between;
    @include lg {
      height: 306px;
      border: 2px solid #1f2229;
      padding-bottom: 0;
    }
    &.black {
      background-color: #1f2229;
      color: #fff;
      .modal-fullness__line {
        background-color: #fff;
      }
      .modal-cost .icon {
        border: 1px solid #1f2229;
      }
      .btn-close {
        border: 2px solid #ffffff;
      }
      .modal__close {
        @include reset-button;
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.7911.2094a.709.709 0 0 0-1.0027 0L9 7.9978 1.2116.2094A.709.709 0 1 0 .2089 1.2121l7.7884 7.7884L.209 16.7889a.709.709 0 0 0 1.0027 1.0027L9 10.0032l7.7884 7.7884a.709.709 0 1 0 1.0027-1.0027l-7.7884-7.7884 7.7884-7.7884a.709.709 0 0 0 0-1.0027Z' fill='%231F2229'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        width: 32px;
        height: 32px;
      }
    }
  }
  &-grid {
    @include lg {
      display: grid;
      grid-template-columns: 375px fit-content(100%) 1fr;
      height: 100%;
      grid-gap: 50px;
    }
  }
  &-column {
    &:first-child {
      @include lg {
        display: flex;
        align-items: flex-end;
        height: 100%;
        background: #fff;
      }
    }
    &:nth-child(2) {
      padding: 0 16px;
      @include lg {
        padding: 30px 0 0 0;
      }
    }
    &:nth-child(3) {
      padding: 0 16px;
      @include lg {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 40px;
      }
    }
  }
  &-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5050;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    opacity: 0.8;
    display: none;
    @include lg {
      display: block;
    }
  }
  &__head {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  &__close {
    @include reset-button;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.7911.2094a.709.709 0 0 0-1.0027 0L9 7.9978 1.2116.2094A.709.709 0 1 0 .2089 1.2121l7.7884 7.7884L.209 16.7889a.709.709 0 0 0 1.0027 1.0027L9 10.0032l7.7884 7.7884a.709.709 0 1 0 1.0027-1.0027l-7.7884-7.7884 7.7884-7.7884a.709.709 0 0 0 0-1.0027Z' fill='%231F2229'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 32px;
    height: 32px;
    &::before {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      z-index: -1;
      cursor: default;
    }
  }
  &__title {
    font-family: var(--fontSerif);
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    @include lg {
      margin-bottom: 16px;
    }
  }
  &-preview {
    margin-bottom: 20px;
    width: 100%;
    @include lg {
      margin-bottom: 0;
    }
  }
  &-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    margin-bottom: 9px;
    &__suit {
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
      margin-bottom: 8px;
      margin-top: 0;
    }

    @include lg {
      margin-bottom: 16px;
    }
  }
  &-cost {
    display: flex;
    align-items: center;
    padding: 0px 5px;
    margin-bottom: 13px;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      background-color: #e9e9ea;
      border: 1px solid #ffffff;
      margin-left: -5px;
    }
    span {
      font-size: 12px;
      line-height: 15px;
      margin-left: 8px;
    }
  }
  &-fullness {
    margin-bottom: 20px;
    display: grid;
    grid-gap: 9px;
    &__item {
      display: grid;
      grid-template-columns:
        fit-content(100%) fit-content(100%) fit-content(100%)
        fit-content(100%) 32px;
      grid-gap: 6px;
      align-items: flex-end;
    }
    &__line {
      width: 72px;
      height: 8px;
      border-radius: 8px;
      background-color: #1f2229;
      opacity: 0.1;
      &.active {
        opacity: 1;
      }
      @include lg {
        width: 50px;
      }
    }
    &__title {
      font-size: 12px;
      line-height: 9px;
      margin-left: 5px;
    }
  }
  &-desc {
    ul {
      @include reset-list;
      li {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 8px;
      }
    }
  }
  .btn-close {
    max-width: 131px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 22px;
    padding: 13px 35px;
    height: auto;
    margin-top: 12px;
    @include lg {
      display: none;
    }
  }
  &-social {
    margin-bottom: 16px;
    &__title {
      margin-bottom: 4px;
      font-size: 14px;
      line-height: 17px;
    }
    .socials {
      display: grid;
      grid-template-columns: repeat(auto-fill, 24px);
      grid-gap: 1px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .wink {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    img {
      width: 28px;
      height: 28px;
    }
    span {
      font-size: 14px;
      line-height: 17px;
      margin-left: 8px;
    }
  }
}
</style>
