<template>
  <Header />
  <h1 class="loading" v-show="isLoading || isGlobalLoading">
    {{ getLoadText }}
  </h1>
  <h1
    class="loading"
    v-show="!isLoading && !isGlobalLoading && !tariffs.length"
  >
    {{ getErrorText }}
  </h1>
  <router-view v-show="!isLoading && !isGlobalLoading && tariffs.length" />
  <Footer />
  <LanguageModal v-show="isOpen && !isLoading && !isGlobalLoading" />
</template>

<script setup>
import Header from "@/components/HeaderComp.vue";
import Footer from "@/components/FooterComp.vue";
import useLanguageModal from "@/hooks/useLanguageModal";
import LanguageModal from "@/components/modals/LanguageModal.vue";
import { computed, watch } from "vue";
import { useStore } from "vuex";
import useLanguage from "@/hooks/useLanguage";

const { isOpen, openModal } = useLanguageModal();
const { getLanguage } = useLanguage();
const store = useStore();

const isLoading = computed(() => store.state.api.isLoading);
const tariffs = computed(() => store.state.api.tariffs);
const isGlobalLoading = computed(() => store.state.api.isGlobalLoading);

watch(tariffs, () => {
  if (tariffs.value.length) openModal();
});

const getLoadText = computed(() => {
  const lang = getLanguage();
  if (lang === "ky") return "Жүктөө";
  if (lang === "tg") return "Боркунӣ";
  if (lang === "uz") return "Yuklash";
  return "Загрузка...";
});

const getErrorText = computed(() => {
  const lang = getLanguage();
  if (lang === "ky") return "Ката";
  if (lang === "tg") return "Хато";
  if (lang === "uz") return "Xato";
  return "Ошибочка";
});
</script>

<style lang="scss">
.loading {
  height: calc(100vh - 92px);
  display: grid;
  place-items: center;
}

:root {
  --colorDark: #272a30;
  --colorDarkSecond: #1f2229;
  --colorLight: #fff;
  --colorLightSecond: #8f9399;
  --colorPink: #ff59a3;
  --widthContainer: 1116px;
  --widthGutter: 16px;
  --fontSans: "Tele2 TextSans", sans-serif;
  --fontSerif: "Tele2 DisplaySerif", sans-serif;
  --fontStencil: "Standard CT Stencil", "Trebuchet MS", Helvetica, sans-serif;
  --sectionOffsetTop: 24px;
  --sectionOffsetBottom: 48px;
  --lineHeight: 1.22;
  --sizeHeading: 22px;
  --sizeHeading1: 22px;
  --sizeHeading2: 19px;
  --sizeHeading3: 16px;
  --sizeHeading4: 14px;
  --sizeHeading5: 14px;
  --sizeHeading6: 12px;
  --sizeText: 14px;
  --cubicBezier: cubic-bezier(0.1, 0.4, 0.9, 0.7);
}

@include md {
  :root {
    --sizeHeading: 54px;
    --sizeHeading1: 36px;
    --sizeHeading2: 30px;
    --sizeHeading3: 24px;
    --sizeHeading4: 20px;
    --sizeHeading5: 18px;
    --sizeHeading6: 16px;
    --sizeText: 18px;
  }
}

*,
::after,
::before {
  box-sizing: border-box;
  outline-color: var(--colorDarkSecond);
}

html {
  min-width: 375px;
}

body {
  margin: 0;
  background: #f3f5f6;
  color: var(--colorDark);
  font-family: var(--fontSans);
  line-height: var(--lineHeight);
}

input,
textarea {
  font-family: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

#app {
  min-height: 100vh;
  display: grid;
  grid-template-rows: max-content auto max-content;
}

.page {
  display: grid;
  place-items: center;
  align-items: start;
  padding: 0 var(--widthGutter);
  &__inner {
    width: 100%;
    max-width: var(--widthContainer);
    height: 100%;
  }
}

.button-back {
  display: block;
  width: 50px;
  height: 50px;
  margin: -16px -15px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='16' viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.1739 7.134H2.8401l5.381-5.6557a.8904.8904 0 0 0 .241-.6125.893.893 0 0 0-.2416-.613A.797.797 0 0 0 7.638 0a.797.797 0 0 0-.5826.2528L.241 7.4162A.8923.8923 0 0 0 0 8.0282c0 .2373.092.4532.2416.6126l6.7592 7.1049A.7945.7945 0 0 0 7.5833 16a.7945.7945 0 0 0 .5826-.2543.8927.8927 0 0 0 .2416-.6125.8925.8925 0 0 0-.2416-.6125L2.7854 8.8646h16.388c.4595-.015.8266-.3967.8266-.8658 0-.4692-.3671-.8509-.8252-.8659l-.0009.001Z' fill='%23fff'/%3E%3C/svg%3E");
  transition: 0.2s;
  // &:hover {
  //   background-position: calc(50% - 2px) 50%;
  // }
}

.title {
  font-family: var(--fontStencil);
  text-transform: uppercase;
  font-weight: bold;
  font-size: var(--sizeHeading1);
  line-height: 1.2;
  @include md {
    line-height: 1.4;
  }
}

.modal-open {
  overflow: hidden;
}

h1 {
  font-weight: 700;
  font-size: 38px;
  line-height: 40px;
  font-family: var(--fontStencil);
  text-transform: uppercase;
  margin: 0;
}
h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  font-family: var(--fontSerif);
  margin: 0;
  margin-top: 18px;
  margin-bottom: 16px;
}
h3 {
  font-weight: 700;
  font-size: 38px;
  line-height: 40px;
  font-family: var(--fontStencil);
  text-transform: uppercase;
  margin: 0;
}
.desc {
  font-size: 18px;
}
.a {
  grid-area: a;
}
.b {
  grid-area: b;
}
.c {
  grid-area: c;
}
.d {
  grid-area: d;
}
.e {
  grid-area: e;
}
.f {
  grid-area: f;
}
.g {
  grid-area: g;
}

.highlight {
  background-color: yellow !important;
}
mark {
  background: none !important;
}
</style>
<style lang="scss" src="@/styles/button.scss"></style>
<style lang="scss" src="@/styles/swiper.scss"></style>
<style lang="scss" src="@/styles/collapse.scss"></style>
