<template>
  <ModalComp class="l-modal">
    <template #default>
      <header class="l-modal__header">
        <div class="l-modal__header-wrap">
          <RouterLink class="l-modal__logo" :to="{ name: 'Home' }">
            <IconLogo />
          </RouterLink>
          <button @click="closeModal()" class="l-modal__close">
            <IconClose />
          </button>
        </div>
      </header>
      <div class="l-modal__body">
        <h2 class="l-modal__title">{{ getText }}</h2>
        <div class="l-modal__items">
          <button
            v-for="item of languages"
            :key="item.code"
            class="l-modal__item"
            type="button"
            @click="
              setLanguage(item.code);
              setQuery({ lang: item.code });
              closeModal();
            "
          >
            <div class="l-modal__item-icon">
              <img
                :src="staticUrl(`static/flags/${item.code}.svg`)"
                :alt="item.code"
                width="54"
                height="40"
              />
            </div>
            <div class="l-modal__item-name">{{ item.name }}</div>
          </button>
        </div>
      </div>
    </template>
  </ModalComp>
</template>

<script setup>
import IconClose from "@/components/icons/IconClose.vue";
import IconLogo from "@/components/icons/IconLogo.vue";
import ModalComp from "@/components/modals/ModalComp.vue";
import useLanguage from "@/hooks/useLanguage";
import useLanguageModal from "@/hooks/useLanguageModal";
import useQuery from "@/hooks/useQuery";
import { staticUrl } from "@/utils";
import { computed } from "vue";

const { closeModal } = useLanguageModal();
const { getLanguage, setLanguage, languages } = useLanguage();
const { setQuery } = useQuery();

const getText = computed(() => {
  const lang = getLanguage();
  if (lang === "ky") return "Тилди тандаңыз";
  if (lang === "tg") return "Забонро интихоб кунед";
  if (lang === "uz") return "Tilni tanlang";
  return "Выберите язык";
});
</script>

<style lang="scss">
.l-modal {
  // .l-modal__header
  &__header {
    background-color: #e9e8ed;
  }
  // .l-modal__header-wrap
  &__header-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 48px;
  }
  // .l-modal__logo
  &__logo {
    color: #191a1f;
  }
  // .l-modal__close
  &__close {
    @include reset-button;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
  // .l-modal__body
  &__body {
    padding: 48px 16px;
    max-width: 320px;
    margin: 0 auto;
  }
  // .l-modal__title
  &__title {
    margin: 0 0 32px;
    font-size: 22px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.01em;
    color: #1f2229;
  }
  // .l-modal__items
  &__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
  // .l-modal__item
  &__item {
    display: flex;
    align-items: center;
    max-width: 144px;
    height: 48px;
    padding: 4px;
    border: 1px solid currentColor;
    border-radius: 8px;
    background: transparent;
    color: #1f2229b2;
    transition: color 0.3s ease-in;
    cursor: pointer;

    &:hover {
      color: #1f2229;
    }
  }
  // .l-modal__item-icon
  &__item-icon {
    img {
      display: block;
    }
  }
  // .l-modal__item-name
  &__item-name {
    margin: 0 auto;
    font-size: 14px;
    font-weight: 400;
    font-family: var(--fontSans);
    line-height: 18px;
    color: currentColor;
    text-transform: uppercase;
  }
}

@media (min-width: 768px) {
  .l-modal {
    background: rgba(255, 255, 255, 0.8);
    .modal__wrap {
      width: 384px;
      border: 1px solid #1f2229;
      background: #ffffff;
    }
    // .l-modal__header
    &__header {
      margin-bottom: 32px;
      background-color: transparent;
    }
    // .l-modal__header-wrap
    &__header-wrap {
      padding: 32px 32px 0;
      height: auto;
      justify-content: flex-end;
    }
    // .l-modal__logo
    &__logo {
      display: none;
    }
    // .l-modal__close
    &__close {
      width: 40px;
      height: 40px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
    // .l-modal__body
    &__body {
      padding: 0 32px 32px;
      max-width: unset;
    }
  }
}
</style>
